import React from 'react';
import styles from './style.module.scss';
import { decodeHTMLEntities, parseHtml } from '@/utils/common';

interface HomeTextProps {
  data: any;
}

const HomeText: React.FC<HomeTextProps> = ({ data }) => {
  return (
    <>
      <section className={styles.hometextsection}>
        <div className={styles.hometextinner}>
          <div className="container-1600">
            <div className={styles.hometextcontent}>
              {parseHtml(decodeHTMLEntities(data?.description))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeText;
